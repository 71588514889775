import React, { Component } from 'react';
import { connect } from 'react-redux';


//componentes
import './Chat.css';
//import Alert from '../../modal/alert'

class ChatAction extends Component {
    constructor(props) {
        super(props);
        this.state = {
            botonOn:true,
            usuarioactivo:'',
            activarchatOn:false,
            botonrecibido:false,
            mensaje:'',
            mensajes:[
                // {id:0, nombre:'Jose', texto:"texto1 esto es una prueba multilinea para verificar si pasa a la otra line y deja el espacio en blanco para el otro remitente"},
                // {id:1, nombre:'Maria', texto:"texto2"},
                // {id:2, nombre:'Pedro', texto:"texto3"}
            ],
        }
    }
    activarchat=()=>{
        if (this.state.botonOn){
            this.setState({
                botonOn:false,
                activarchatOn:true,
            })
        }else{
            this.setState({
                botonOn:true,
                activarchatOn:false,
                botonrecibido:false,
            })

        }
        
    }

    onChange(e) {
        //console.log(e.target.name)
        this.setState({
            [e.target.name]: e.target.value
             
        })
        // e.getElementById('final').scrollIntoView(true)
        
      }

    enviar=(e)=>{
        const nuevoMensaje = {
            id: this.state.mensajes.length,
            nombre: this.props.usuario1.usuario,
            texto: this.state.mensaje 
        };
        // window.firebase.database().ref(`mensajes/${nuevoMensaje.id}`)
        // .set(nuevoMensaje);
        this.setState({mensaje:''});

        let elmnt = document.getElementById("capa2");
        elmnt.scrollTop = 100000;
    }
 
    enviar2=(nom)=>{
        const nuevoMensaje = {
            id: this.state.mensajes.length,
            nombre: this.props.usuario1.usuario,
            texto: nom 
        };
        // window.firebase.database().ref(`mensajes/${nuevoMensaje.id}`)
        // .set(nuevoMensaje);
        this.setState({mensaje:''});
        
  
    }
    componentDidMount(){
        //   window.firebase.database().ref(`mensajes/`).on('value', snap => {
        //       const mensajesconcurrentes= snap.val();
        //       if (mensajesconcurrentes!==null){
        //           this.setState({mensajes:mensajesconcurrentes, botonrecibido:true});
        //       }
        //   });
          let nom='A iniciado Sesion';
          this.enviar2(nom)
          nom='';
      }

    render(){

        const {mensajes}= this.state; 
        const listaMensaje=mensajes.map(mensaje =>{
            return <li key={mensaje.id}>
                        <ul><span className="remitente">{mensaje.nombre}:</span>&nbsp;{mensaje.texto}</ul>
                    </li>
                  
        })
         // console.log("paso: "+this.props.usuario1.usuario)
        // console.log(this.state.activarchatOn)
        return(
            
            <div className="cont-chat row">
                {this.state.activarchatOn &&
                    <div className="cont-chat-formulario col-3">
                        <div className="cot-titulochat col-12">
                            <span className="titulochat">Chat del Sistema</span>
                        </div>
                        <div className="row">
                            <div className="capa1" id="capa1">
                                <div className="capa2" id="capa2">
                                    {listaMensaje}
                                </div>
                            </div>
                        </div>
                        <div className="cont-texto row">
                            <div className=" inputtext col-9">
                            <input 
                                type="text"
                                className="form-control" 
                                id="mensaje"
                                name="mensaje"
                                value={this.state.mensaje}
                                onChange={this.onChange.bind(this)} 
                                onKeyPress={event => { if (event.key === 'Enter') {this.enviar()} }}
                            />
                            </div>
                            <div className="botochat col-3">
                                <button className="botonch" onClick={this.enviar.bind(this)}>Enviar</button>
                            </div>
                        </div>
                    </div>
                }
                { this.props.usuario1.usuario &&
                    <button 
                        className={this.state.botonOn === true && this.state.botonrecibido===false ? "botonF1" : this.state.botonOn === true && this.state.botonrecibido===true ? "mensajerecibido" : "botonF2"}
                        onClick={this.activarchat.bind(this)}
                    >
                        <span className="imagenpng">-</span>
                    </button>
                }
            </div>
            
        )
    }
}
const mapStateToProps = (state) => {
    return {
        usuario1: state.usuario1,
        token: state.token
    }
}

export default connect(mapStateToProps,) (ChatAction);        